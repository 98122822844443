<script lang="ts" setup>
useHead({ title: '' })

const [individualsHovered, toggleIndividualsHovered] = useToggle(false)

const productsSection = ref()
const contactSection = ref()

function scrollToProducts () {
  productsSection.value.scrollIntoView({ behavior: 'smooth' })
}
function scrollToContact () {
  contactSection.value.scrollIntoView({ behavior: 'smooth' })
}
</script>

<template>
  <div>
    <!-- hero section -->
    <div bg-gradient-to-br dark:bg-gradient-to-b from="primary-200 dark:neutral-50" via-primary-50 dark="via-50% via-primary-50" to="primary-100 dark:neutral-100" min-h-500px>
      <div fubex-container py-28px>
        <div bg="primary-200/50 dark:primary-100/30" w-fit mx-auto flex flex-row justify-center items-center gap-8px fubex-rounded-lg px-8px md:px-12px py-4px md:py-8px my-16px>
          <div :class="individualsHovered ? 'text-black dark:text-white' : 'bg-primary-500 text-white dark:bg-primary-300'" py-8px px-16px fubex-rounded-md cursor-default duration-350>
            <span hidden sm:block>Řešení pro firmy</span>
            <span block sm:hidden>Firmy</span>
          </div>
          <div>
            <NuxtLink to="/smena-kryptomen" hover="bg-accent-500 dark:bg-accent-300 text-black" py-8px px-16px fubex-rounded-md block duration-350 @mouseenter="toggleIndividualsHovered()" @mouseleave="toggleIndividualsHovered()">
              <span hidden sm:block>Služby pro jednotlivce</span>
              <span block sm:hidden>Jednotlivci</span>
            </NuxtLink>
          </div>
        </div>
        <div flex flex-row items-center>
          <div flex flex-col gap-40px my-40px>
            <h1 display-sm font-700 text-center lg:text-left>
              Poskytujeme komplexní řešení pro všechny typy právnických osob
            </h1>
            <div block lg:hidden min-w="130% sm:80%">
              <img src="/img/homepage-img.webp">
            </div>
            <p><strong>Fakturujte</strong> v kryptoměnách a vytvářejte jednoduše faktury, využijte našeho softwaru pro spuštění <strong>privátní směnárny</strong> a využívejte možnosti <strong>nadlimitních nákupů</strong></p>
            <div flex flex-col sm:flex-row gap-x-8px gap-y-0px text-24px font-500 self-center lg:self-start>
              <div flex items-center gap-8px hover:bg-primary-100 rounded-lg>
                <UnoIcon i-fubex-stopwatch inline-block text-accent-500 dark:text-accent-300 h-30px w-30px />Rychle
              </div>
              <div flex items-center gap-8px hover:bg-primary-100 rounded-lg>
                <UnoIcon i-fubex-lightning inline-block text-accent-500 dark:text-accent-300 h-28px w-28px mx-3px />Jednoduše
              </div>
              <div flex items-center gap-8px hover:bg-primary-100 rounded-lg>
                <UnoIcon i-fubex-safe inline-block text-accent-500 dark:text-accent-300 h-26px w-26px mx-1px />Bezpečně
              </div>
            </div>
            <div w-fit self-center lg:self-start>
              <FButton name="services" size="lg" suffix-icon="i-fubex-arrow-down-circle" @click="scrollToProducts">
                Naše produkty
              </FButton>
            </div>
          </div>
          <div hidden lg:block min-w="60%">
            <img src="/img/homepage-img.webp">
          </div>
        </div>
      </div>
    </div>
    <!-- /hero section -->

    <!-- partner logos carousel -->
    <div my-100px flex flex-col gap-32px>
      <h2 paragraph-md text-neutral-400 font-700 text-center uppercase>
        Naši partneři
      </h2>
      <WidgetPartnerCarousel />
    </div>
    <!-- /partner logos carousel -->

    <!-- company verification -->
    <div fubex-container my-100px>
      <h2 heading1 font-700 text-center>
        Založení účtu na pár kliků
      </h2>
      <p text-center max-w-3xl mx-auto my-20px>
        Snadné, rychlé a automatizované vytvoření firemního účtu, které vám ušetří mnoho času i trápení s vypisováním složitých formulářů, které by vás jinde čekaly. Ověření a spuštění v rámci hodin.
      </p>
      <WidgetBenefitsCompanies />
    </div>
    <!-- /company verification -->

    <!-- our products -->
    <div ref="productsSection" fubex-container my-100px flex flex-col gap-32px pt-40px>
      <h2 paragraph-md text-neutral-400 font-700 text-center uppercase>
        Naše produkty
      </h2>
      <div grid grid-cols-1 md:grid-cols-2 items-center>
        <div flex flex-col gap-20px z-10>
          <h2 heading2 font-700>
            Nákup kryptoměn na firmu
          </h2>
          <p>Nákupujte a prodávajte kryptoměny za výhodných podmínek. Máme pro vás připraveno bezpečné a přehledné uživatelské rozhraní, ve kterém se snadno vyznáte. </p>
          <h3 heading6 font-700>
            Jaké jsou výhody nákupů kryptoměn na firmu?
          </h3>
          <ul flex flex-col gap-8px>
            <LiWithCheck>
              Ochrana firemních rezerv před inflací, která snižuje hodnotu státních peněz a připravuje vás o úspory.
            </LiWithCheck>
            <LiWithCheck>
              Snížení podnikatelského rizika spjatého se svévolí státu a dalších třetích stran, například bank.
            </LiWithCheck>
            <LiWithCheck>
              Využití možností, které přinášejí moderních techlologie a zhodnocení firemního kapitálu.
            </LiWithCheck>
          </ul>
          <div flex flex-col sm:flex-row items-center gap-24px my="20px">
            <WidgetAuthDialogs default-view="register">
              <FButton name="create-account" size="lg">
                Vytvořit účet
              </FButton>
            </WidgetAuthDialogs>
            <NuxtLink to="/nakup-kryptomen-na-firmu" basic-link>
              Zjistit více informací
            </NuxtLink>
          </div>
        </div>
        <div relative>
          <Ellipse />
          <img src="/img/macbook.webp" z-10 relative>
        </div>
      </div>
    </div>
    <!-- /our products -->

    <!-- crypto payments -->
    <div fubex-container my-100px flex flex-col gap-32px>
      <div grid grid-cols-1 md:grid-cols-2 items-center>
        <div flex flex-col gap-20px z-10>
          <h2 heading2 font-700>
            Platba faktur kryptoměnami
          </h2>
          <p>Příjímejte kryptoměny za vaše produkty a služby. Vystavování faktur nikdy nebylo jednodušší.</p>
          <h3 heading6 font-700>
            Proč akceptovat kryptoměny jako platidlo?
          </h3>
          <ul flex flex-col gap-8px>
            <LiWithCheck>Konkurenční výhoda. Zvýšíte svou atraktivitu pro nové zákazníky i spokojenost těch stávajících.</LiWithCheck>
            <LiWithCheck>Budujete image své značky. Ukazujete světu, že jste firma, která se v době digitálních technologií neztratí.</LiWithCheck>
            <LiWithCheck>Platby bez hranic. Nadlimitní nebo mezinárodní transakce v řádu minut, které banka nezbrzdí, nezastaví a ani neprodraží.</LiWithCheck>
          </ul>
          <div flex flex-col sm:flex-row items-center gap-24px my="20px">
            <WidgetAuthDialogs default-view="register">
              <FButton name="create-account" size="lg">
                Vytvořit účet
              </FButton>
            </WidgetAuthDialogs>
            <NuxtLink to="/platba-faktur-kryptomenami" basic-link>
              Zjistit více informací
            </NuxtLink>
          </div>
        </div>
        <div relative md:order-first w="90%" h-full pt-40px>
          <Ellipse />
          <img src="/img/macbook-2.webp" z-10 relative>
        </div>
      </div>
    </div>
    <!-- /crypto payments -->

    <!-- newsletter -->
    <div id="subscribe" :data-test="getCypressId('newsletter')" fubex-container p-16px mb-48px relative z-10>
      <WidgetNewsletter
        request-type="document"
        title="Poklad na účetní rozvaze"
        text="Objevte několik důvodů, proč je výhodné nakupovat kryptoměny na firmu."
        toast-text="Děkujeme, dokument Vám přijde do e-mailu."
        button-title="Stáhnout"
      />
    </div>
    <!-- /newsletter -->

    <!-- whitelabel software -->
    <div bg-neutral-50 border-t-1 border-neutral-200 mt-134px>
      <div fubex-container flex flex-col gap-32px relative py-80px>
        <div grid grid-cols-1 md:grid-cols-2 items-center>
          <div flex flex-col gap-20px z-10>
            <h2 heading2 font-700>
              Směnárenský software
            </h2>
            <p>Získejte online směnárnu kryptoměn ve firemních barvách a na vlastní doméně. </p>

            <ul flex flex-col gap-8px>
              <LiWithCheck>
                Nabídněte své komunitě nákup a prodej kryptoměn za individuálních podmínek.
              </LiWithCheck>
              <LiWithCheck>
                O nic se nestaráte! Vypořádání transakcí, verifikaci uživatelů i klientskou podporu řešíme za vás.
              </LiWithCheck>
              <LiWithCheck>
                Nasazení a spuštění směnárny proběhne v rámci několika dní.
              </LiWithCheck>
            </ul>
            <div flex flex-col md:flex-row items-center gap-24px my="20px">
              <FButton name="create-account" size="lg" @click="scrollToContact()">
                Mám zájem o software
              </FButton>
              <NuxtLink to="/smenarensky-software" basic-link>
                Více podrobností o softwaru
              </NuxtLink>
            </div>
          </div>
          <div relative>
            <Ellipse />
            <img src="/img/tablet-tower.webp" z-10 relative>
          </div>
        </div>
      </div>
    </div>
    <!-- /whitelabel software -->

    <!-- where is software used -->
    <div bg-neutral-50 border-t-1 border-neutral-200>
      <div fubex-container py-80px flex flex-col gap-80px>
        <h2 heading2 font-700 text-center>
          Kdo už spokojeně používá náš software
        </h2>
        <WidgetReferencesCarousel @scroll-to-contact="scrollToContact()" />
      </div>
    </div>
    <!-- /where is software used -->

    <!-- contact form -->
    <div ref="contactSection">
      <WidgetContactForm>
        <h2 heading2 font-700 text-white>
          Kontaktní formulář
        </h2>
      </WidgetContactForm>
    </div>
    <!-- /contact form -->
  </div>
</template>
