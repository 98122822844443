<template>
  <div bg-neutral-100 dark:bg-neutral-200 my-40px px-40px py-32px fubex-rounded-md grid grid-cols-1 lg:grid-cols-3 gap-y-40px gap-x-20px>
    <div flex flex-col gap-16px>
      <h3 heading6 font-700 flex flex-row justify-start items-center gap-8px>
        <UnoIcon class="i-fubex-dollar-circle" text-accent-500 dark:text-accent-300 h-24px w-24px /> Rychlá verifikace
      </h3>
      <p>Firemní registrace na burzách trvá dny až týdny a velmi vás zatíží. Máme unikátní proces, který řeší řadu věcí za vás.</p>
    </div>
    <div flex flex-col gap-16px>
      <h3 heading6 font-700 flex flex-row justify-start items-center gap-8px>
        <UnoIcon class="i-fubex-bank" text-accent-500 dark:text-accent-300 h-24px w-24px /> Jednoduchost a bezpečnost
      </h3>
      <p>Platforma pro právnické osoby s intuitivním rozhraním a  klientskou podporou v češtině. </p>
    </div>
    <div flex flex-col gap-16px>
      <h3 heading6 font-700 flex flex-row justify-start items-center gap-8px>
        <UnoIcon class="i-fubex-credit-card" text-accent-500 dark:text-accent-300 h-24px w-24px /> Flexibilní platby
      </h3>
      <p>Přijímejte platby v kryptoměnách v jakékoli výši. Pohodlné nastavení plateb na míru vašemu byznysu.</p>
    </div>
  </div>
</template>
